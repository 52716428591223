import React, { useEffect } from 'react';
import { Modal } from 'antd';
import {
    setDisplayModal,
    setDisplayScreen,
    setDisplaySteps,
    setClearResponse,
    setFinalResponse,
} from 'reduxStore/slices/totters.slice';
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks';
import ContactForm from 'components/generals/forms/contact';
import { set } from 'react-hook-form';
export default function ContactModal() {
    const dispatch = useAppDispatch();
    const displayModal = useAppSelector((state) => state.tottersSlice.displayModal);

    return (
        <Modal
            width={600}
            title=""
            open={displayModal}
            centered
            footer={null}
            onCancel={() => {
                // dispatch(setDisplayModal(false));
                // dispatch(setDisplayScreen(true));
                // dispatch(setDisplaySteps(false));
                // dispatch(setDisplaySteps(false));
                // dispatch(setClearResponse([]));
                // dispatch(setFinalResponse(''));

                window.location.reload();
            }}
        >
            <main className="totter__modal">
                <section className="totter__modal__content">
                    {displayModal && <ContactForm />}
                </section>
            </main>
        </Modal>
    );
}
