import React, { useState, useEffect } from 'react';
import { tottersFormData } from 'data/tottersForms';
import { useAppSelector, useAppDispatch } from 'reduxStore/hooks';
import Characters from './characters';
import StepBar from './stepbar';
import ActionButton from 'components/ui/buttons/action-button';
import Animated from 'components/generals/AnimatedComponent';
import {
    setFormData,
    setDisplayModal,
    setDisplayScreen,
    setFinalResponse,
} from 'reduxStore/slices/totters.slice';
import { Steps, QuestionService, Answer } from 'interfaces/form.interface';
import RenderAnimated from 'helpers/rederAnimate';
import getInitializationData from 'helpers/initQuestions';
import { useTranslation } from 'react-i18next';

export default function StepsForm() {
    const tottersServices = useAppSelector((state) => state.tottersSlice.tottersServices);
    const tottersResponse = useAppSelector((state) => state.tottersSlice.tottersResponse);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [state, setState] = useState({
        current: 0,
        active: '',
        color: 'pink',
        steps: [] as Steps[],
        colors: [] as string[],
        finalResponses: [] as string[],
        animationKey: 0,
        selectedIndices: [] as number[],
        answerSelected: [] as string[],
        isMultiple: false,
        questionType: '',
        question: '',
        answer: '' as string | string[],
        questionServices: [] as QuestionService[],
    });

    const updateState = (key: keyof typeof state, value: any | ((prev: any) => any)) => {
        setState((prevState) => ({
            ...prevState,
            [key]: typeof value === 'function' ? value(prevState[key]) : value,
        }));
    };

    const updateColor = (newCurrent: number) => {
        if (newCurrent >= 0 && newCurrent < state.colors.length) {
            updateState('color', state.colors[newCurrent]);
        }
    };

    const next = () => {
        updateState('animationKey', (prev: number) => prev + 1);
        updateState('current', (prevCurrent: number) => {
            const newCurrent = prevCurrent + 1;
            updateColor(newCurrent);
            return newCurrent;
        });
    };

    const handleAnswer = ({ type, index, option, question }: Answer) => {
        const { current, steps } = state;
        updateState('question', t(question));
        const handleMultiple = () => {
            updateState('answerSelected', (prevSelectedOption: any) =>
                prevSelectedOption.includes(option)
                    ? prevSelectedOption.filter((i: string) => i !== option)
                    : [...prevSelectedOption, option],
            );

            

            updateState('selectedIndices', (prevSelectedIndices: any) =>
                prevSelectedIndices.includes(index)
                    ? prevSelectedIndices.filter((i: number) => i !== index)
                    : [...prevSelectedIndices, index],
            );
        };

        if (type === 'single') {

            dispatch(setFinalResponse(t(state.finalResponses[index])));
            dispatch(
                setFormData({
                    answer: t(option),
                    question: t(question),
                }),
            );

            current < steps.length - 1 ? next() : finishForm();
        } else {
            handleMultiple();
        }
    };

    const finishForm = () => {
        dispatch(setDisplayModal(true));
        dispatch(setDisplayScreen(false));
    };


    useEffect(() => {
        const initializeState = (
            servicesType: QuestionService[],
            colors: string[],
            steps: { title: string }[],
            finalResponses: string[],
        ) => {
            updateState('questionServices', servicesType);
            updateState('colors', colors);
            updateState('steps', steps);
            updateState('finalResponses', finalResponses);
        };

        getInitializationData(tottersServices);

        const questions =
            tottersServices === 0
                ? (tottersFormData.serviceType.multiple.questions as QuestionService[])
                : (tottersFormData.serviceType.single.questions as QuestionService[]);

        const colors = tottersServices === 0 ? ['pink', 'green', 'yellow'] : ['pink', 'yellow'];

        const steps =
            tottersServices === 0
                ? [{ title: t("generals.step_1") }, { title: t("generals.step_2") }, { title: t("generals.finalize") }]
                : [{ title: t("generals.step_1") }, { title: t("generals.finalize") }];

        const finalResponse =
            tottersServices === 0
                ? tottersFormData.serviceType.multiple.finalResponse
                : tottersFormData.serviceType.single.finalResponse;

        initializeState(questions, colors, steps, finalResponse);
    }, [t]);

    const items = state.steps.map((item) => ({ key: item.title, title: item.title }));
    return (
        <>
            <section className="totters__base">
                {RenderAnimated(
                    `${state.animationKey}-image-${state.current}`,
                    'animate__zoomIn',
                    <Characters current={state.current} />,
                )}

                {RenderAnimated(
                    `${state.animationKey}-question-${state.current}`,
                    'animate__zoomIn',
                    <h4
                        className={`totters__base__question question__line question__line--${state.color}`}
                    >
                        {t(state.questionServices[state.current]?.question)}
                    </h4>,
                )}

                {RenderAnimated(
                    `${state.animationKey}-option-${state.current}`,
                    'animate__fadeInUp',
                    state.questionServices[state.current]?.type === 'multiple' && (
                        <p>{t("generals.multiple_option")}</p>
                    ),
                )}

                <div className="totters__base__options">
                    {state.questionServices[state.current]?.options.map((option, index: number) => (
                        <Animated
                            key={`${state.animationKey}-${index}`}
                            animation={`animate__fadeInUp animate__delay-${index}s`}
                        >
                            <ActionButton
                                title={t(option)}
                                type="simple"
                                key={index}
                                color={state.color}
                                otherStyles={`${state.selectedIndices.includes(index) ? 'totters__button--active' : ''} ${
                                    state.questionServices[state.current]?.size === 'large'
                                        ? 'totters__button--big_option'
                                        : ''
                                }`}
                                onClick={() => {
                                    const { type, question } =
                                        state.questionServices[state.current];

                                    const questionData = {
                                        type,
                                        question,
                                        option,
                                        index,
                                    };
                                    handleAnswer(questionData);
                                }}
                            />
                        </Animated>
                    ))}
                </div>

                {state.selectedIndices.length > 0 && (
                    <Animated key={`btn-continue`} animation={'animate__bounceIn'}>
                        <ActionButton
                            title={t('generals.continue')}
                            type="action"
                            key={'continue'}
                            color={state.color}
                            onClick={() => {
                                if (state.current < state.steps.length - 1) {
                                    dispatch(
                                        setFormData({
                                            answer: t(state.answerSelected),
                                            question: t(state.question),
                                        }),
                                    );
                                    next();
                                }
                                updateState('selectedIndices', []);
                            }}
                        />
                    </Animated>
                )}
            </section>

            <StepBar current={state.current} items={items} steps={state.steps} />
        </>
    );
}
