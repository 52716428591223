import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Providers } from './reduxStore/provider';
import { BrowserRouter } from 'react-router-dom';
import ContactModal from 'components/generals/modal/contact';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Providers>
        <App />
        <ContactModal />
    </Providers>,
);
