import React from 'react';
import 'styles/settings/fonts.css';
import 'styles/styles.scss';
import HomePage from 'pages/home';
import LanguangeSelector from 'components/generals/language-selector';
import 'animate.css';
import './i18n';
function App() {
    return (<>
        <LanguangeSelector/>
        <HomePage />;
        </>)
    
}

export default App;
