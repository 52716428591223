import React from 'react';
import InitForm from 'components/generals/initForm';
import StepsForm from 'components/generals/steps';
import { useAppSelector } from 'reduxStore/hooks';



export default function HomePage() {

    const displaySteps = useAppSelector((state) => state.tottersSlice.displaySteps);
    const displayScreen = useAppSelector((state) => state.tottersSlice.displayScreen);

    return (
        <main className="main__page main__page--full nv__home__page">
            <section className="totters__patter__mobile"></section>
            <section className="totters">
                <section className="totters__patter"></section>
                <section className="totters__form">
                    {displayScreen && (
                        <section className="totters__form__container">
                            {displaySteps ? <StepsForm /> : <InitForm />}
                        </section>
                    )}
                </section>
            </section>
        </main>
    );
}
