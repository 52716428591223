const config = {
    ENVIROMENT: process.env.REACT_APP_ENVIROMENT,
    API_URL: process.env.REACT_APP_API_URL,
    SITE_URL: process.env.REACT_APP_SITE_URL,
    EMAIL_FOLDER: process.env.REACT_APP_EMAIL_FOLDER,
    EMAIL_FROM_SEND: process.env.REACT_APP_EMAIL_FROM_SEND,
    EMAIL_TO_SEND: process.env.REACT_APP_EMAIL_TO_SEND,
    EMAIL_TEMPLATE: process.env.REACT_APP_EMAIL_TEMPLATE
};

export default config;