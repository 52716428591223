import { configureStore } from "@reduxjs/toolkit";
import calculatorSlice from "./slices/calcutator.slice";
import contactSlice from "./slices/contact.slice";
import tottersSlice from "./slices/totters.slice";
import { mailingApi } from "reduxStore/services/mailing.services";



export const store = configureStore({
    reducer: {
        calculatorSlice,
        contactSlice,
        tottersSlice,
        [mailingApi.reducerPath]: mailingApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat([
            mailingApi.middleware,
        ]),
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
