import React from 'react';
import { Button, message, Steps } from 'antd';

export default function StepBar({
    current,
    steps,
    items,
}: {
    current: number;
    steps: any;
    items: any;
}) {

    return <Steps current={current} items={items} />;
}
