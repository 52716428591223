import React from 'react';

import Animated from 'components/generals/AnimatedComponent';

const RenderAnimated = (keySuffix: string, animation: string, content: React.ReactNode) => (
    <Animated key={`${keySuffix}`} animation={animation}>
        {content}
    </Animated>
);

export default RenderAnimated;
