import React, { useEffect, useState } from 'react';
import { NVInput } from 'components/generals/inputs/nv__input';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactFormSchema } from 'schemas/contact.form.schema';
import { ContactFormInterface } from 'interfaces/contact.form.interface';
import { SendFormData } from 'interfaces/mail.interface';
import Characters from 'assets/images/totters-characters.png';
import neuro from 'assets/images/neuro.png';
import { TypeAnimation } from 'react-type-animation';
import Animated from 'components/generals/AnimatedComponent';
import { setFinalResponse } from 'reduxStore/slices/totters.slice';
import {  useAppSelector } from 'reduxStore/hooks';
import { useSendFormDataMutation } from 'reduxStore/services/mailing.services';
import Confetti from 'components/generals/confetti';
import ActionButton from 'components/ui/buttons/action-button';
import config from 'config';
import { useTranslation } from 'react-i18next';


export default function ContactForm() {

    const tottersResponse = useAppSelector((state) => state.tottersSlice.tottersResponse);
    const finalResponse = useAppSelector((state) => state.tottersSlice.finalResponse);
    const [sendEmailData] = useSendFormDataMutation();
    const [displayThanks, setDisplayThanks] = useState(false);
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<ContactFormInterface>({
        resolver: yupResolver(contactFormSchema),
        shouldFocusError: false,
    });

    console.log('totterResponse', tottersResponse);

    const onSubmit: SubmitHandler<ContactFormInterface> = async (data) => {
  
        try{


        
        const formData: SendFormData & { [key: string]: any } = {
            folder: config.EMAIL_FOLDER as string,
            templateHTML: config.EMAIL_TEMPLATE as string,
            fromEmail: config.EMAIL_FROM_SEND as string,
            ToEmail: config.EMAIL_TO_SEND as string,
            subject: t("contact.subject"),
            language:t("contact.language"),
            name: data.name,
            email: data.email,
            whatsapp: data.whatsapp,
            attachments: [{
                "filename": "totters.png",
                "cid": "totters@cid"
            },
            {
                "filename": "totters-characters.png",
                "cid": "totterscharacters@cid"
            },
            {
                "filename": "neuro.png",
                "cid": "neuro@cid"
            }],
            service: finalResponse
        };
        
        tottersResponse.forEach((item:any, index:number) => {
            const question = `question_${index + 1}`;
            const response = `response_${index + 1}`;
        
            formData[question] = item.question;
            formData[response] = Array.isArray(item.answer) ? item.answer.join(", ") : item.answer;
        });


        console.log('formData', formData);
            setDisplayThanks(true);
            const __sendFormData = await sendEmailData(formData).unwrap();
            console.log('__sendFormData', __sendFormData);
            reset();
    }catch(e){
        console.log('Error', e);
    }
    };

    return (
        <main className="totters__contact__form">
            {!displayThanks ? (
                <div className="totters__contact__form__block">
                    <div className="contact__form__base">
                        <div className="contact__form__base__container">
                            {finalResponse !== '' && (
                                <TypeAnimation
                                    sequence={[t(finalResponse), 2000]}
                                    wrapper="h3"
                                    speed={20}
                                    repeat={Infinity}
                                />
                            )}

                            <Animated key={`init-characters`} animation={'animate__fadeInUp'}>
                                <div className="totters__contact__form__characters">
                                    <img src={Characters} alt="Totter" />
                                </div>
                            </Animated>

                            <Animated key={`init-form`} animation={'animate__fadeInUp'}>
                                <div className="contact__form__fields">
                                    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                        <NVInput
                                            name="name"
                                            type="text"
                                            placeholder={t('contact.names')}
                                            control={control}
                                            className=""
                                            errorMessage={errors.name?.message}
                                        />

                                        <NVInput
                                            name="email"
                                            type="text"
                                            placeholder={t('contact.email')}
                                            control={control}
                                            className=""
                                            errorMessage={errors.email?.message}
                                        />

                                        <NVInput
                                            name="whatsapp"
                                            type="text"
                                            placeholder={'WhatsApp'}
                                            control={control}
                                            className=""
                                            errorMessage={errors.whatsapp?.message}
                                        />

                                        <input
                                            className="transition"
                                            type="submit"
                                            value={t('contact.submit')}
                                        />
                                    </form>
                                </div>
                            </Animated>
                        </div>
                    </div>
                </div>
            ) : (
                <Animated key={`init-characters-thanks-0`} animation={'animate__fadeInDown'}>
                    <section className="totters__contact__form__thanks">
                        <Confetti />
                        <Animated
                            key={`init-characters-thanks-1`}
                            animation={'animate__fadeInDown'}
                        >
                            <div className="totters__contact__form__thanks__characters">
                                <img src={neuro} alt="neuro" />
                            </div>
                        </Animated>
                        <Animated key={`init-characters-thanks-2`} animation={'animate__fadeInUp'}>
                            <h3>
                                {t("thanks.message_1")} <br />
                                {t("thanks.message_2")}
                            </h3>

                            <ActionButton
                                title={t("thanks.button")}
                                type="simple"
                                key={`keybutton-thanks`}
                                color={'pink'}
                                onClick={() => {
                                    window.location.reload();
                                }}
                            />
                        </Animated>
                    </section>
                </Animated>
            )}
        </main>
    );
}
