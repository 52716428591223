import React, { ReactElement, useState } from 'react';

interface ActionButtonProps {
    title: string;
    onClick?: () => void;
    icon?: ReactElement;
    otherStyles?: string;
    color?: string;
    type: string;
}
export default function ActionButton({
    title,
    onClick,
    icon,
    otherStyles,
    type,
    color,
}: ActionButtonProps) {
    return (
        <>
            {type === 'simple' && (
                <button
                    className={`totters__button totters__button--${color} transition ${otherStyles}`}
                    onClick={onClick}
                >
                    {title} {icon}
                </button>
            )}

            {type === 'action' && (
                <button
                    className={`totters__button totters__button--action transition ${otherStyles}`}
                    onClick={onClick}
                >
                    {title} {icon}
                </button>
            )}
        </>
    );
}
