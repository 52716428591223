import React from 'react';
import neuro from 'assets/images/neuro.png';
import tottercircle from 'assets/images/TotterYellow.gif';
import tottersbluegreen from 'assets/images/totters-blue-green.jpg';

export default function Characters({ current }: { current: number }) {
    return (
        <div className="totters__image totters__image--small">
            {current === 0 && <img src={neuro} alt="Totters" />}
            {current === 1 && <img src={tottersbluegreen} alt="Totters" />}
            {current === 2 && <img src={tottercircle} alt="Totters" />}
        </div>
    );
}
