import { tottersFormData } from 'data/tottersForms';
import { QuestionService } from 'interfaces/form.interface';

function getInitializationData(tottersServices: number) {
    if (tottersServices === 0) {
        return {
            questions: tottersFormData.serviceType.multiple.questions as QuestionService[],
            colors: ['pink', 'green', 'yellow'],
            steps: [{ title: 'Paso 1' }, { title: 'Paso 2' }, { title: 'Finalizar' }],
        };
    } else {
        return {
            questions: tottersFormData.serviceType.single.questions as QuestionService[],
            colors: ['pink', 'yellow'],
            steps: [{ title: 'Paso 1' }, { title: 'Finalizar' }],
        };
    }
}

export default getInitializationData;
