
export const tottersFormData = 
    {
        initialQuestion:{
            question:"form.initialQuestion.question",
            options:["form.initialQuestion.option_1","form.initialQuestion.option_2"],
            type:"single", 
        },
        serviceType:{
            multiple:
            {
                questions: [
                    {
                        number:1,
                        question:"form.multiple.question_1.question",
                        options: [
                            "form.multiple.question_1.option_1", 
                            "form.multiple.question_1.option_2", 
                            "form.multiple.question_1.option_3", 
                            "form.multiple.question_1.option_4"
                        ],
                        type:"single",
                        size:"small"
                        
                    },
                    {
                        number:2,
                        question:"form.multiple.question_2.question",
                        options: [
                            "form.multiple.question_2.option_1", 
                            "form.multiple.question_2.option_2", 
                            "form.multiple.question_2.option_3", 
                            "form.multiple.question_2.option_4",
                            "form.multiple.question_2.option_5"
                            ],
                        type:"multiple",
                        size:"small"
                    },
                    {
                        number:3,
                        question:"form.multiple.question_3.question",
                        options: [
                            "form.multiple.question_3.option_1", 
                            "form.multiple.question_3.option_2", 
                            "form.multiple.question_3.option_3", 
                            "form.multiple.question_3.option_4",
                            "form.multiple.question_3.option_5",
                            "form.multiple.question_3.option_6",
                            "form.multiple.question_3.option_7",
                            "form.multiple.question_3.option_8",
                            "form.multiple.question_3.option_9"
                        ],
                        type:"single",
                        size:"large"
                    }
                ],
                finalResponse:[
                    "form.multiple.final_response.option_1",
                    "form.multiple.final_response.option_2",
                    "form.multiple.final_response.option_3",
                    "form.multiple.final_response.option_4",
                    "form.multiple.final_response.option_5",
                    "form.multiple.final_response.option_6",
                    "form.multiple.final_response.option_7",
                    "form.multiple.final_response.option_8",
                    "form.multiple.final_response.option_9"
                ]
            },
            single:
                {
                    questions: [ 
                        {
                            number:1,
                            question:"form.simple.question_1.question",
                            options: [
                                    "form.simple.question_1.option_1", 
                                    "form.simple.question_1.option_2", 
                                    "form.simple.question_1.option_3", 
                                    "form.simple.question_1.option_4",
                                    "form.simple.question_1.option_5",
                                ],
                            type:"single",
                            size:"small"
                        },
                        {
                            number:2,
                            question:"form.simple.question_2.question",
                            options: [
                                    "form.simple.question_2.option_1", 
                                    "form.simple.question_2.option_2", 
                                    "form.simple.question_2.option_3", 
                                    "form.simple.question_2.option_4",
                                    "form.simple.question_2.option_5",
                                    "form.simple.question_2.option_6",
                                    "form.simple.question_2.option_7",
                                    "form.simple.question_2.option_8",
                                    "form.simple.question_2.option_9",
                                    "form.simple.question_2.option_10"
                                ],
                            type:"single",
                            size:"large"
                        }
                    ],
                    finalResponse:[
                        "form.simple.final_response.option_1",
                        "form.simple.final_response.option_2",
                        "form.simple.final_response.option_3",
                        "form.simple.final_response.option_4",
                        "form.simple.final_response.option_5",
                        "form.simple.final_response.option_6",
                        "form.simple.final_response.option_7",
                        "form.simple.final_response.option_8",
                        "form.simple.final_response.option_9",
                        "form.simple.final_response.option_10"
                    ]
                }
            
            
        }
        
    }