import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type tottersState = {
    tottersResponse: any,
    displayScreen: boolean
    displaySteps: boolean
    tottersServices: number,
    option: string | string[],
    answer: string,
    displayModal: boolean
    finalResponse: string
}
const initialState = {
    tottersResponse:[],
    displaySteps: false,
    displayScreen:true,
    tottersServices: 0,
    option: "",
    answer: "test",
    displayModal: false,
    finalResponse: ""


} as tottersState;

export const tottersSlice = createSlice({
    name: "tottersSlice",
    initialState,
    reducers: {

        setFormData: (state, action: PayloadAction<{  answer: string | string[]; question:string  }>) => {
            const {  answer, question } = action.payload;

            state.tottersResponse = [...state.tottersResponse, action.payload]
        },

        setDisplaySteps: (state, action: PayloadAction<boolean>) => {
            state.displaySteps = action.payload
        },

        setTotterServices: (state, action: PayloadAction<number>) => {
            state.tottersServices = action.payload
        },

        setAnswer: (state, action: PayloadAction<string>) => {
            state.answer = action.payload
        },

        setDisplayModal: (state, action: PayloadAction<boolean>) => {
            state.displayModal = action.payload
        },
        setDisplayScreen: (state, action: PayloadAction<boolean>) => {
            state.displayScreen = action.payload
        },

        setClearResponse: (state, action: PayloadAction<any>) => {
            state.tottersResponse = action.payload
        },

        setFinalResponse: (state, action: PayloadAction<string>) => {
            state.finalResponse = action.payload
        }
    },

});

export const {
    setFormData,
    setDisplaySteps,
    setDisplayModal,
    setAnswer,
    setTotterServices,
    setDisplayScreen,
    setClearResponse,
    setFinalResponse
} = tottersSlice.actions;
export default tottersSlice.reducer;
