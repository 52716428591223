import React from 'react';
import characters from 'assets/images/totters-characters.png';
import { tottersFormData } from 'data/tottersForms';
import { useAppDispatch } from 'reduxStore/hooks';
import { setDisplaySteps, setTotterServices } from 'reduxStore/slices/totters.slice';
import ActionButton from 'components/ui/buttons/action-button';
import Animated from 'components/generals/AnimatedComponent';
import { setFormData } from 'reduxStore/slices/totters.slice';
import { useTranslation } from 'react-i18next';

export default function InitForm() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    return (
        <section className="totters__base">
            <Animated key={`init-characters`} animation={'animate__fadeInDown'}>
                <div className="totters__characters">
                    <img src={characters} alt="neuro" />
                </div>
            </Animated>

            <Animated key={`init-question`} animation={'animate__zoomIn'}>
                <h3 className="totters__base__question totters__base__question--base">
                    {t(tottersFormData.initialQuestion.question)}
                </h3>
            </Animated>

            <section className="totters__base__options totters__base__options--small">
                {tottersFormData.initialQuestion.options.map((option, index) => (
                    <Animated
                        key={`init-form-${index}`}
                        animation={`animate__fadeInUp animate__delay-${index}s`}
                    >
                        <ActionButton
                            title={t(option)}
                            type="simple"
                            key={`keybutton-${option}`}
                            color={'yellow'}
                            onClick={() => {
                                dispatch(setDisplaySteps(true));
                                dispatch(
                                    setFormData({
                                        answer: t(option),
                                        question: t(tottersFormData.initialQuestion.question),
                                    }),
                                );

                                if (t(option) === 'Sí' || t(option) === 'Yes') {
                                    dispatch(setTotterServices(0));
                                } else {
                                    dispatch(setTotterServices(1));
                                }
                            }}
                        />
                    </Animated>
                ))}
            </section>
        </section>
    );
}
