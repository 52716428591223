import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
export default function LanguangeSelector() {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState('es');

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
    };

    return (
        <div className="tt__language__selector transition">
                {language === 'es' ? (
                    <div
                        className="tt__language__selector__item transition"
                        onClick={() => changeLanguage('en')}
                    >
                    ENG
                    </div>
                ) : (
                    <>
                        <div
                            className="tt__language__selector__item transition"
                            onClick={() => changeLanguage('es')}
                        >
                        ESP
                        </div>
                    </>
                )}
        </div>
    );
}
